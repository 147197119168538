import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt"
import { FaAddressCard } from "@react-icons/all-files/fa/FaAddressCard"

import "./agent-card.css"

const AgentCard = ({ agent }) => {
  //TODO: maybe should be added to a helper class
  const getAgentVCard = () => {
    let vCardBuilder = `BEGIN:VCARD\n`
    vCardBuilder += `VERSION:2.1\n`
    vCardBuilder += `N:${agent.MemberLastName};${agent.MemberFirstName}\n`
    vCardBuilder += `FN:${agent.MemberFullName}\n`
    vCardBuilder += `ADR;HOME;PREF:;;${agent.MemberAddress1} ${agent.MemberAddress2
      };${agent.MemberCity};;${""}\n`
    vCardBuilder += `ORG:${agent.OfficeName}\n`
    vCardBuilder += `TITLE:${""}\n`
    vCardBuilder += `TEL;HOME;VOICE:${agent.MemberOfficePhone}\n`
    vCardBuilder += `TEL;CELL;VOICE:${agent.MemberMobilePhone}\n`
    vCardBuilder += `EMAIL;PREF;INTERNET:${agent.MemberEmail}\n\n\n\n`
    vCardBuilder += `END:VCARD\n`

    let vFileName = `${agent.MemberFirstName}-${agent.MemberLastName}.VCF`

    let blob = new Blob([vCardBuilder], {
      type: "text/vcard;charset=utf-8",
    })

    let vcardURL = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = vcardURL
    link.download = vFileName

    document.body.appendChild(link)

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )

    window.URL.revokeObjectURL(vcardURL)

    document.body.removeChild(link)
  }
  //TODO: maybe should be added to a helper class
  const getAgentOfficePhone = () => {
    let agentOfficePhone = agent.MemberOfficePhone
      ? agent.MemberOfficePhone
      : agent.MemberPreferredPhone
        ? agent.MemberPreferredPhone
        : null
    return agentOfficePhone
  }

  const agentOfficePhone = getAgentOfficePhone()

  return (
    <div className="agent-card-container flex flex-row w-96 sm:w-96 xl:w-120 h-56 xl:h-56">
      <div className="w-2/6">
        {agent.photo ? (
          <img
            className="object-cover h-full w-full"
            alt={agent.MemberFullName}
            src={process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 200).replace('HEIGHT', (200 / 0.75)) + agent.photo.url}
          />
        ) : agent.Media && JSON.parse(agent.Media).length > 0 ? (
          <img
            className="object-cover h-full w-full"
            alt={agent.MemberFullName}
            src={process.env.MEDIA_CACHE_URL_PREPEND.replace('WIDTH', 200).replace('HEIGHT', (200 / 0.75)) + JSON.parse(agent.Media)[0].Uri}
          />
        ) : (
          <StaticImage
            className="object-cover h-full"
            alt="agent"
            src="../images/no_agent_placeholder.png"
          />
        )}
      </div>
      <div className="flex flex-col justify-around w-4/6 pl-3 xl:pl-5 pr-2 xl:pr-4 py-2 h-full">
        <h2 className="subhead3">
          {agent.firstName && agent.lastName
            ? `${agent.firstName} ${agent.lastName}`
            : agent.MemberFullName}
          <span className="block uppercase info">
            {agent.title || "BROKER ASSOCIATE"}
          </span>
        </h2>
        <div className="flex flex-col justify-around flex-nowrap items-center w-full py-2">
          {agentOfficePhone ? (
            <div className="flex flex-row flex-nowrap items-baseline w-full xl:pb-1">
              <div className="agent-card-contact-icon-box">
                <FaPhoneAlt />
              </div>
              <div className="agent-card-contact-value-box">
                <a href={`tel:${agentOfficePhone}`}>{agentOfficePhone}</a>
              </div>
            </div>
          ) : null}
          {agent.MemberMobilePhone ? (
            <div className="flex flex-row flex-nowrap items-baseline w-full xl:pb-1">
              <div className="agent-card-contact-icon-box">
                <FaMobileAlt />
              </div>
              <div className="agent-card-contact-value-box">
                <a href={`tel:${agent.MemberMobilePhone}`}>
                  {agent.MemberMobilePhone}
                </a>
              </div>
            </div>
          ) : null}
          {agent.MemberEmail ? (
            <div className="flex flex-row flex-nowrap items-center w-full xl:pb-1">
              <div className="agent-card-contact-icon-box">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    getAgentVCard()
                  }}
                  aria-hidden="true"
                >
                  <FaAddressCard />
                </span>
              </div>
              <div className="agent-card-contact-value-box">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    getAgentVCard()
                  }}
                  aria-hidden="true"
                >
                  Download vCard
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <Link
            className="agent-link"
            to={"/people/" + (agent.MemberFirstName ? `${agent.MemberCity}-Real-Estate-Broker-${agent.MemberFirstName}-${agent.MemberLastName}` : `Real-Estate-Broker-${agent.firstName}-${agent.lastName}`)}
          >
            <button
              className="agent-button py-1 xl:py-2 bg-white border border-solid border-black cursor-pointer w-full button2"
            >
              About {agent.MemberFirstName || agent.firstName}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AgentCard
