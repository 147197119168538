import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AgentList from "../components/agent-list"
// import AgentHeaderImg from "../images/agent-header.jpg"

const People = ({ data, location }) => {
  const allPosts = data.allAspenSnowmassAgent.nodes;

  return (
    <Layout>
      <Seo title="People" location={location} />
      <div className="agent-list-header">
        <h1 className="h1-header">
          Our Agents
        </h1>
      </div>

      <AgentList agentList={allPosts} />
    </Layout>
  )
}

export default People

export const query = graphql`
  query MyQuery2 {
    allAspenSnowmassAgent {
      nodes {
        MemberKey
        MemberFirstName
        MemberLastName
        MemberFullName
        MemberPreferredPhone
        MemberOfficePhone
        MemberMobilePhone
        MemberEmail
        Media
        MemberCity
        MemberAddress1
        MemberAddress2
        OfficeName
        MemberMlsId
        firstName
        lastName
        mlsId
        photo {
          url
        }
        title
        agentBio
      }
    }
  }
`
